import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from 'components/layout'
import PageHeader from 'components/page-header'

const CommunityLeadershipPage = ({ data }) => (
  <Layout>
    <PageHeader 
      title="Community Leadership" 
      sizes={data.headerImage.childImageSharp.fluid} 
    />
      
    <div className="l-primary">
      <nav>
        <h3>Industry &amp; Community</h3>
        <ul>
          <li><Link to={'/industry-and-community/industry-leadership'}>Industry Leadership</Link></li>
          <li><Link to={'/industry-and-community/community-leadership'}>Community Leadership</Link></li>
        </ul> 
      </nav>
      <section>
        <h2>Delaware County Farm Fest</h2>
        <p>The Delaware County Farm Festival is an annual event promoting agricultural education for schools and the surrounding community. It is a two day educational event filled with farm animals, fun activities, an auction, and lots of food.</p>

        <h2>Crossroads Lutheran Church Historical Preservation Society (CLCHPS)</h2>
        <p>David Howell lead the initiative to found the Crossroads Lutheran Church Historical Preservation Society. Our family remains actively involved with the preservation of the historic Church at Crossroads. <a href={'http://thechurchatcrossroads.org/clchps'} target={'_blank'}>Learn more at the CLCHPS website</a></p>

        <h2>Annual Down Syndrome Pumpkin Sale</h2>
        <p>Our family sponsors an annual pumpkin sale supporting Down Syndrome Indiana in honor of Aaron and Katy's daughter, Kate.</p>
      </section>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "community-leadership-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default CommunityLeadershipPage
